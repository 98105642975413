import React, { useEffect, useRef, useState } from 'react';
import { getLogger } from '../../utils/logger';
import { DimensionValue } from '../../interfaces/logger';
import {
	ContentOptions,
	FrameOptions,
	createEmbeddingContext,
	EmbeddingEvents,
	ExperienceFrameMetadata,
	EventMessageValues,
	DashboardExperience,
} from 'amazon-quicksight-embedding-sdk';
import {
	Box,
	Button,
	Header,
	SpaceBetween,
	StatusIndicator,
} from '@amzn/awsui-components-react';
import './QuickSightPage.scss';
import useSettings from '../../hooks/useSettings';
import { useIntl } from 'react-intl';
import message from './QuickSightPage.messages';
import { useAdminReportUrlContext } from '../../contexts/AdminReportUrlContextProvider';
import { FetchStatus } from '../../constants/fetchStatus';
import ErrorMessage from '../ErrorPage/Common';
import { analyticsClickEvent } from '../../analytics';

const QuickSightPage = () => {
	const initTime = useRef(new Date().getTime());
	const logger = getLogger();
	const { formatMessage } = useIntl();
	const { settings } = useSettings();
	const { status, url, error } = useAdminReportUrlContext();
	const [dashboard, setDashboard] = useState({} as DashboardExperience);

	const analyticsSheetChangeCB = (data: EventMessageValues) => {
		analyticsClickEvent([
			'quicksight',
			'sheet',
			data?.selectedSheet.Name,
		])();
	};

	/**
	 * Only changed params that occur in the 0 index of the emitted event data are used.
	 * If the number of parameter changes is greater than 1, it doesn't reflect a change made by human interaction
	 * Quicksight refresh these parameter values which emits a changeParameter event for all parameters when there is a refresh
	 * Or when there is a tab/sheet change. Do not emit clickEvent during these cases as it will make the metrics inconsistent.
	 */
	const analyticsParametersChangeCB = (data: EventMessageValues) => {
		const changedParamCount = data.changedParameters.length;
		const changedParamName = data.changedParameters[0].Name;
		if (changedParamName !== 'TopN' && changedParamCount === 1) {
			analyticsClickEvent(['quicksight', 'params', changedParamName])();
		}
	};

	useEffect(() => {
		const embed = async (
			frameOptions: FrameOptions,
			contentOptions: ContentOptions,
		) => {
			const embeddingContext = await createEmbeddingContext();
			const { embedDashboard } = embeddingContext;
			const df = await embedDashboard(frameOptions, contentOptions);

			setDashboard(df);
		};
		if (url) {
			const frameOptions: FrameOptions = {
				url: url,
				container: document.getElementById(
					'embeddingContainer',
				) as HTMLElement,
				resizeHeightOnSizeChangedEvent: true,
				width: '100%',
				onChange: (changeEvent, metadata) => {
					switch (changeEvent.eventName) {
						case 'FRAME_LOADED': {
							if (metadata && metadata.frame) {
								metadata.frame.title =
									'Skill Builder Admin Reporting Dashboard';
							}
							break;
						}
					}
				},
			};
			const contentOptions: ContentOptions = {
				locale: 'en-US',
				toolbarOptions: {
					undoRedo: true,
					reset: true,
				},
				sheetOptions: {
					emitSizeChangedEventOnSheetChange: true, // use this option in combination with resizeHeightOnSizeChangedEvent property,
					// to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
				},
				attributionOptions: {
					overlayContent: true,
				},
				onMessage: async (
					messageEvent: EmbeddingEvents,
					metadata?: ExperienceFrameMetadata,
				) => {
					switch (messageEvent.eventName) {
						case 'SELECTED_SHEET_CHANGED': {
							analyticsSheetChangeCB(messageEvent.message);
							break;
						}
						case 'PARAMETERS_CHANGED': {
							analyticsParametersChangeCB(messageEvent.message);
							break;
						}
					}
				},
			};

			embed(frameOptions, contentOptions);
		}
	}, [url, setDashboard]);

	useEffect(() => {
		logger.counterMetric({
			metricName: 'QuickSightPage',
			dimensionValue: DimensionValue.Traffic,
		});
	}, [logger]);

	useEffect(() => {
		if (
			[
				FetchStatus.Loaded,
				FetchStatus.ExpectedError,
				FetchStatus.UnexpectedError,
			].includes(status)
		) {
			const loadTime = new Date().getTime() - initTime.current;
			logger.timerMetric({
				metricName: 'QuickSightPageLoadTime',
				metricValue: loadTime,
				dimensionValue: DimensionValue.Performance,
			});
		}
	}, [status, logger]);

	if (status === FetchStatus.ExpectedError && error) {
		return <ErrorMessage errorName={error.name} />;
	}

	if (status === FetchStatus.UnexpectedError) {
		throw error;
	}

	if (status === FetchStatus.Unspecified || status === FetchStatus.Loading) {
		return (
			<Box textAlign="center">
				<StatusIndicator type="loading">{'Loading'}</StatusIndicator>
			</Box>
		);
	}

	const renderPrintButton = () => {
		return (
			dashboard && (
				<Button
					data-testid="btn-print-or-save-pdf"
					className="print-button"
					iconAlign="left"
					iconName="download"
					ariaLabel="Print or save as PDF"
					onClick={() => {
						analyticsClickEvent(['CTA', 'Print PDF Button'])();
						dashboard.initiatePrint();
					}}
				>
					Print or save as PDF
				</Button>
			)
		);
	};

	return (
		<div data-testid="QuickSightPage" className="quickSightContent">
			<div className="report-header">
				<Header
					variant="h1"
					actions={
						<SpaceBetween direction="horizontal" size="s">
							<Button
								data-testid="btn-return-to-dashboard"
								variant="primary"
								ariaLabel="Return to Admin Dashboard"
								href={
									settings?.skillbuilderReports
										.administratorDasboard
								}
								onClick={analyticsClickEvent([
									'CTA',
									'Return to Admin Dashboard',
								])}
							>
								{formatMessage(message.returnToDashboard)}
							</Button>
						</SpaceBetween>
					}
				>
					{formatMessage(message.quickSightPageHeader)}
				</Header>
			</div>
			{renderPrintButton()}
			<div
				id="embeddingContainer"
				data-testid="quicksight-iframe-container"
			></div>
		</div>
	);
};

export default QuickSightPage;
